import React from 'react';
import styles from './js.module.css';

const TableHeader = ({headerStrings}) => {
  return (
    <thead>
      <tr>
        {
          headerStrings.map((headerString, i) => {
            return <th key={`${headerString}-${i}`}>{headerString}</th>;
          })
        }
      </tr>
    </thead>
  );
};

const Js = () => {
  return (
    <>
      <h1>JavaScript</h1>
      <h2>RegExp</h2>
      <table className={styles.contentTable}>
        <TableHeader headerStrings={['Code', 'Returns', 'Example']} />
        <tbody>

          <tr>
            <td>
              <pre>
                {`
"string".match(/regexp/g)
                `.trim()}
              </pre>
            </td>
            <td>
              <pre>
                {`
[array]
                `.trim()}
              </pre>
            </td>
            <td>
              <pre>
                {`
let text = "The best is awesome";
let result = text.match(/[abc]/g);
// [ "b", "a" ]
                `.trim()}
              </pre>
            </td>
          </tr>

          <tr>
            <td>
              <pre>
                {`
"string".replace(/regexp/g, "string")
                `.trim()}
              </pre>
            </td>
            <td>
              <pre>
                {`
"string"
                `.trim()}
              </pre>
            </td>
            <td>
              <pre>
                {`
const message = 'How are you?';
const messageLetters = message.replace(/[^A-Za-z]+/g, '');
// "Howareyou"
                `.trim()}
              </pre>
            </td>
          </tr>

          <tr>
            <td>
              <pre>
                {`
/regexp/.test("string")
                `.trim()}
              </pre>
            </td>
            <td>
              <pre>
                {`
boolean
                `.trim()}
              </pre>
            </td>
            <td>
              <pre>
                {`
const message = '           ';
const isSilence = /^\\s*$/.test(message);
// true
                `.trim()}
              </pre>
            </td>
          </tr>

        </tbody>
      </table>
    </>
  );
};

export default Js;
