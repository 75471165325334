import React from 'react';
import { Link } from "react-router-dom";

const Pubkey = () => {
  return (
    <>
      <h1>Public Key</h1>
      <Link to="/michael.asc" reloadDocument>Link to my public GPG key</Link>
    </>
  );
};

export default Pubkey;