import React from 'react';

const Radio = () => {
  return (
    <>
      <h1>Radio</h1>
      <a href="https://radio.social.net.ua">Radio Social</a>
    </>
  );
};

export default Radio;
