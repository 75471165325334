import React from 'react';
import { Link } from 'react-router-dom';
import MaboxProof from '../img/mabox-gxkb.png';
import styles from './liberation.module.css';

type CardType = {
  kind: string,
  proofImage?: string,
  proofText?: string,
  proofUrl?: string,
  title: string,
  url: string,
};

const Card = ({ kind, proofImage = '', proofText = '', proofUrl= '', title, url }: CardType) => {
  const heading = <h3 className={styles[kind.toLowerCase()]}>{kind}:</h3>;
  const isProof = proofImage || proofText || proofUrl;
  return (
    <>
      <div className={styles.card}>
        {heading}
        <div className={styles['card-body']}>
          <p>{title}:<br /><Link to={url} reloadDocument>{url}</Link></p>
          {
            isProof ? (
              <p>Proof:<br />
                { proofText ? <span>{proofText}</span> : '' }
                { proofUrl ? <Link to={proofUrl} reloadDocument>{proofUrl}</Link> : '' }
                { proofImage ? <img alt="proof url" src={proofImage} /> : '' }
              </p>
            ) : ''
          }
        </div>
      </div>
    </>
  );
};

const Liberation = () => {
  return (
    <>
      <h1>Liberation Movement</h1>
      <p>
        "Open source is good for humanity. It's only slightly hyperbolic to say that open source
        is one of the most notable collective successes of humankind as a species! It's one of the
        few places where essentially all of humanity works together on something that benefits
        everyone. A world without open source would be substantially worse than the world we live
        in". (<Link to="https://jacobian.org/2024/feb/16/paying-maintainers-is-good" reloadDocument>
          source</Link>)
      </p>
      <h2>Operating System</h2>
      <div className={styles.flex}>
        <Card
          kind="Good"
          proofImage={MaboxProof}
          title="Mabox Linux"
          url="https://maboxlinux.org"
        />
        <Card
          kind="Bad"
          proofUrl="https://lists.debian.org/debian-vote/2022/03/msg00263.html"
          title="Debian"
          url="https://www.debian.org"
        />
      </div>
      <h2>Search Engine</h2>
      <div className={styles.flex}>
        <Card
          kind="Good"
          proofText="Search term 'лінукс' gave 2/17 results in androphages' language, 'mabox nvidia' showed relevant results"
          title="Monocles"
          url="https://monocles.eu/search"
        />
        <Card
          kind="Bad"
          proofText="Search term 'лінукс' gave 4/20 results in androphages' language, but 'mabox nvidia' showed unrelevant results"
          title="Brave"
          url="https://search.brave.com"
        />
        <Card
          kind="Bad"
          proofText="Search term 'лінукс' gave 5/10 (8/10 in video section) in androphages' language"
          title="DuckDuckGo"
          url="https://duckduckgo.com"
        />
      </div>
        <Card
          kind="Alternative"
          title="Searchlab"
          url="https://searchlab.eu/search"
        />
        <Card
          kind="Build your own"
          title="Yacy"
          url="https://yacy.net"
        />
      <h2>Social Network</h2>
      <div>
        <p>Pleroma</p>
      </div>
      <h2>Messenger</h2>
      <div>
        <Card
          kind="Good"
          title="XMPP"
          url="https://miliukhin.xyz/articles/matrix_and_xmpp"
        />
      </div>
      <div>
        <Link
          to="https://kr-labs.com.ua/blog/top-secure-and-privacy-messaging-apps"
          reloadDocument
        >
          У пошуках безпечного месенджера
        </Link>
      </div>
      <h2>Email</h2>
      <div>
      </div>
      <h2>Github</h2>
      <div>
        <p>Gitea</p>
      </div>
      <h2>Friends of Ukraine</h2>
      <div>
        <p>https://www.redhat.com/en/blog/red-hats-response-war-ukraine</p>
        <p>https://canonical.com/blog/canonical-standing-with-ukraine</p>
        <p>https://github.blog/2022-03-02-our-response-to-the-war-in-ukraine/</p>
        <p>https://blog.zorin.com/2022/03/10/zorin-os-16-1-released-support-for-ukraine/</p>
        <p>https://deadbeef.sourceforge.io/</p>
      </div>
    </>
  );
};

export default Liberation;
