import React from 'react';
import CopyFunctionImage from '../img/copy-function.jpg';

const Wisdomry = () => {
  return (
    <>
      <h1>Wisdomry</h1>
      <h2>Software Engineering</h2>
      <img
        alt="You copied that function without understanding"
        src={CopyFunctionImage}
        title="You copied that function without understanding"
      />
    </>
  );
};

export default Wisdomry;
